import { Theme, makeStyles, createStyles } from '@material-ui/core'
import React from 'react'
import { PageRow } from '../../Common'
import BackgroundImage from '../../../images/top-view-parents-spending-time-with-kids.png'
import LongContactForm from '../../ContactFormFullDialog/ContactForm'
import ShortContactForm from '../../NewContactForm/ContactForm'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root:{
        marginTop:'50px',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: 'calc(100vw / 1.85)',
        backgroundColor:'white',
        textAlign:'center',
        [theme.breakpoints.down(1440)]:{
            height: 750,
        }
    },
    contactForm:{
        width: 520,
        marginTop: -90,
        [theme.breakpoints.down('sm')]:{
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    }
}))

interface Props {
    longContactFormDialogProcess?: boolean
}

const PageHeader = (props: Props) => {
    const { longContactFormDialogProcess = false } = props
    const classes = useStyles()

    return(
        <PageRow className={classes.root}>
            <div className={classes.contactForm}>
             { longContactFormDialogProcess ? <LongContactForm/> : <ShortContactForm/> }
            </div>
        </PageRow>
    )
}

export default PageHeader
