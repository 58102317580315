import { Tab, Tabs, Paper, Theme, makeStyles, createStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TabPanel } from '../Common'
import PostContactFormResult from './PostContactFormResult'
import InsuranceSelectionForm from './InsuranceSelectionForm'
import { withInsurance } from '../HCO/withInsurance'
import { updateContactForm, createContactForm, initContact } from '../../actions' 
import { connect } from 'react-redux'
import { IContact } from '../../api'
import { InsuranceType } from '../../helpers/insurance'

const useStyles = makeStyles((theme: Theme) =>createStyles({
    root:{
        marginLeft:50,
        marginRight:50,
        paddingLeft:40, 
        paddingRight:40,
        paddingBottom:20,
        width:'calc(100% - 180px)',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        opacity: 0.9,
        [theme.breakpoints.down('xs')]:{
            marginLeft:5,
            marginRight:5,
            width:'calc(100% - 90px)'
        }
    }
}))

interface Props{
    insurance?: InsuranceType
    updateContactForm: (contactForm: IContact) => void
    createContactForm: (contactForm?: IContact) => void
    initContact: (contactForm?: IContact) => void
}

const ContactForm = (props: Props) =>{
    const { insurance, updateContactForm, createContactForm } = props

    const classes = useStyles()

    useEffect(() => {
        createContactForm()
        updateContactForm({ insuranceType: insurance })
    }, [createContactForm, updateContactForm, insurance])

    return(
        <Paper className={classes.root} elevation={3}>        
            <div>
                <InsuranceSelectionForm insurance={insurance}/>
            </div>
        </Paper>
    )

}

const ContactFormWithInsurance = withInsurance(ContactForm)

export default connect(null, { updateContactForm, createContactForm, initContact })(ContactFormWithInsurance)